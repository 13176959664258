.Header-link {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    color: #000 !important;
}

.dropdown-item {
    color: rgba(255, 255, 255, 0.5);
}

.dropdown-item:hover {
    background-color: transparent;
    color: rgba(255, 255, 255, 1);
}

.Figure-link:hover .Figure .Figure-arrow {
    background-color: transparent;
    border-color: transparent transparent rgb(174, 228, 181) transparent;
    border-width: 0 0 160px 160px;
}

.Figure-link.disabled {
    cursor: not-allowed;
}

.Figure {
    position: relative;
    overflow: hidden;
}

.Figure::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(35deg, rgba(48, 27, 25, 0.45) 0%, rgba(48, 27, 25, 0) 80%, rgba(48, 27, 25, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33301b19', endColorstr='#00301b19',GradientType=1);
    height: 100%;
    width: 100%;
    z-index: 0;
}

.Figure-caption {
    bottom: 0;
    color: #f7f7f7;
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    padding: .1rem .3rem;
    position: absolute;
    width: 100%;
    z-index: 9;
}

.Figure-arrow {
    background-color: transparent;
    color: rgb(174, 228, 181);
    height: 0;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 0 120px 120px;
    position: absolute;
    right: 0;
    transform: rotate(360deg);
    transition: all 0.2s ease;
    top: -1rem;
    width: 0;
}

.Figure-link:hover .Figure .Figure-arrow i {
    color: #fff;
}

.Figure-arrow i {
    font-size: 1.7rem;
    font-weight: bold;
    position: absolute;
    right: 1rem;
    top: 2rem;
}

.Inscription {
    background-image: url(../files/img/bgRed.png)
}

.Inscription .container {
    background-color: #f0f1f2;
}

.navbar-lang {
    position: absolute;
    top: 0;
    right: 2rem;
    background-color: #e8e46a;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    color: white;

    .nav-item {
        border-right: 1px solid white;
        margin: 0.3em;

        &:last-of-type {
            border: none;
        }
    }

    .nav-link {
        padding: 0.1em 0.7em;
        font-size: 0.8rem;
        color: white;
        font-weight: 600;

        &:hover {
            opacity: 0.7;
            text-decoration: none;
        }

        &.is-active {
            font-weight: 700;

            &:hover {
                opacity: 1;
            }
        }
    }
}

// .navbar-lang {
//     ul {
//         margin: 0;
//         padding: 0;
//         list-style: none;
//         text-align: right;
//         li {
//             display: inline;
//             padding: 0 0.5rem;
//             a {
//                 font-weight: 700;
//                 color: grey;
//                 &:hover {
//                     color: rgba(grey, 0.8)
//                 }
//             }
//         }
//     }
// }

// .navbar-lang {
//     margin: 0;
//     padding: 0;
//     list-style: none;
//     #navbarLanguage {
//         &:after {
//             margin-left: 1.8rem;
//             width: 10px;
//             color: #fff;
//         }
//         &.language-spa {
//             background-image: url("../../assets/files/img/spa.svg");
//             background-repeat: no-repeat;
//         }
//         &.language-por {
//             background-image: url("../../assets/files/img/por.svg");
//             background-repeat: no-repeat;
//         }
//     }
//     .dropdown-menu {
//         right: 14px;
//         left: unset;
//         padding: 0;
//         margin: 0;
//         min-width: 90px;
//         background: none;
//         @media (max-width: 991px) {
//             right: unset;
//             left: -16px;
//         }
//     }
// }

.Header {
    .navbar-nav {
        .nav-link {
            font-size: 0.9rem;
        }
    }
}

.inscription-wrapper {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin-top: 15px;
    .color-link {
        width: 100%;
        margin-top: 5px;
    }
}

#navbarSupportedContent {
    justify-content: flex-start !important;
    flex-flow: row wrap;
    
    .navbar-nav {
        flex-wrap: wrap;
        justify-content: center;
    }
}