.CarouselIndex--stages {
    background-color: rgba(174, 228, 181, 1);
    height: 100%;
}

.CarouselIndex--stages-inner {
    height: 100%;
}

.CarouselIndex--stages-item {
    height: 100%;
}

.CarouselIndex--stages-figure {
    padding: 1rem;
    width: 100%;
}

.CarouselIndex--stages-img {
    width: 233px;
    height: 233px;
}

.CarouselIndex--stages-title {
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
}

.CarouselIndex--stages-distance {
    color: #80a685;
    font-weight: 700;
}

.CarouselIndex--stages-link {
    color: #e8e46a;
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    font-size: .9rem;
    padding: 1rem;
    text-transform: uppercase;
}

.CarouselIndex--stages-link:hover {
    color: #b6b11c;
    text-decoration: none;
}