.u-title {
    display: inline-block;
    padding: 10px;
    padding-right: 80px;
    border-radius: 3px;
    color: #000;
    background-color: #e8e46a;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

a:hover .u-title  {
    color: #b6b11c;
    text-decoration: none;
}

.u-subtitle {
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    line-height: 1.25;
}

.u-bgRed {
    background-image: url('../files/img/bgRed.png');
    height: 2rem;
}
