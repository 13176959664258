@font-face {
    font-family: 'Work Sans Regular';
    src: url('../files/fonts/WorkSans-Regular.ttf');
}

@font-face {
    font-family: 'Work Sans Bold';
    src: url('../files/fonts/WorkSans-Bold.ttf');
}

@font-face {
    font-family: 'Oswald Medium';
    src: url('../files/fonts/Oswald-Medium.ttf');
}

@font-face {
    font-family: 'Oswald Bold';
    src: url('../files/fonts/Oswald-Bold.ttf');
}

