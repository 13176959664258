.carouselCaption-title {
    background-color: rgba(0, 0, 0, 0.7);
    color: #e8e46a;
    font-family: "Oswald";
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.carouselCaption-ins {
    background-color: rgba(255, 255, 255, 1);
    font-family: "Oswald";
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.carouselCaption-clock {
    background-color: rgba(0, 0, 0, 0.7);
}

#clockContainer{
    color: #000;
}

#clockContainer > div{
    background: #e8e46a;
    padding: 10px;
    margin: 0.1rem;
}

#clockContainer div > span{
    background: #b6b11c;
    padding: 15px;
    font-family: "Oswald";
    font-weight: 700;
    font-size: 1.5rem;
}

.carouselCaption-text {
    font-size: .9rem;
    padding-top: 5px;
}


@media screen and (max-width: 991px) {
    .carouselCaption {
        position: relative;
        left: auto;
        top: auto;
    }

    .carouselCaption-pic {
        display: none;
    }

    .carouselCaption-footer {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .carouselCaption {
        left: 50%;
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
    }
    
    .carouselCaption-pic {
        background-image: url('../files/img/pic.svg');
        background-position: right;
        background-repeat: no-repeat;
        display: block;
        height: 59px;
    }

    .carouselCaption-footer {
        background-color: rgba(255, 255, 255, 1);
    }
}