@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./_fonts";
@import "./_utilities";
@import "./_header";
@import "./_footer";
@import "./_buttons";
@import "./_carouselIndex";
@import "./_carouselIndex--stages";
@import "./_promos";
@import "./_turn";
@import "./_rules";
@import "./_prices";
@import "./_stage";
@import "./_carouselCaption";
@import "./_puntos";
@import "./_index";

body {
    background-color: #fff;
    color: #444;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
}
