.IndexPromo {
    background-image: url("../files/img/promo.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 7rem;
}

.IndexPromo-title {
    display: inline-block;
    padding: 10px;
    padding-right: 80px;
    border-radius: 3px;
    color: #000;
    background-color: #e8e46a;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
}