.Point-img {
    width: 40px;
}

.Point-link {
    color: rgba(68, 68, 68, 1);
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.Point-link:hover, .Point-link:active {
    color: rgba(68, 68, 68, .5);
    text-decoration: none;
}

.Point-link:focus {
    text-decoration: none;
}

.Point-url {
    color: rgba(68, 68, 68, 1);
}

.Point-url:hover {
    color: rgba(68, 68, 68, .5);
    text-decoration: none;
}

.Point-url span {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    margin-left: .5rem;
}