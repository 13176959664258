.Button--inscription {
  color: #000;
  cursor: pointer;
  background-color: #e8e46a;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
}

.Button--follow {
  color: #000;
  cursor: pointer;
  background-color: #e8e46a;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.Button--follow-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.Button--inscripted {
  color: #000;
  cursor: pointer;
  background-color: rgba(174, 228, 181, 1);
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
}

.color-link {
  margin: 0 0.5rem;
}

.Button--inscription:hover {
  background-color: #b6b11c;
}

.Button--inscripted:hover {
  background-color: rgba(174, 228, 181, 0.8);
}

.Button--turn {
  display: inline-block;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.75rem 1.25rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #e8e46a;
  color: #000;
  font-weight: 700;
  font-size: 0.9rem;
}

.Button--turn:hover {
  background-color: #b6b11c;
  color: #000;
}

.Button--stages {
  background-color: transparent;
  border: 1px solid #e8e46a;
  color: #e8e46a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.Button--stages:hover {
  background-color: #e8e46a;
  color: #fff;
}

.Button--seguro {
  background-color: #e8e46a;
  border: 1px solid #e8e46a;
  color: #444;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 80px;
}

.Button--seguro:hover {
  background-color: #e8e46a;
  color: #fff;
}

.Button--inscriptionLink {
  background-color: #f0f1f2;
  border: 1px solid #e8e46a;
  color: #e8e46a;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}

.Button--inscriptionLink:hover {
  background-color: #b6b11c;
  color: #fff;
}
