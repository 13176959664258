.Index {
  height: 100vh;
}

.Index-container,
.Index-row,
.Index-300,
.Index-200,
.Index-100 {
  height: inherit;
}

.Index-200,
.Index-300,
.Index-100 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Index-200::after,
.Index-300::after,
.Index-100::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    35deg,
    rgba(48, 27, 25, 0.45) 40%,
    rgba(48, 27, 25, 0) 80%,
    rgba(48, 27, 25, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33301b19', endColorstr='#00301b19',GradientType=1);
  height: 100%;
  width: 100%;
  z-index: 0;
}

.Index-300 {
  background-image: url("../files/img/epic300.jpg");
}

.Index-200 {
  background-image: url("../files/img/epic200.jpg");
}

.Index-100 {
  background-image: url("../files/img/epic100.png");
}

.Index-caption {
  background-image: url("../files/img/bgRed.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 5%;
  position: absolute;
  z-index: 9;
  text-align: center;
}

.Index-caption:hover {
  text-decoration: none;
}

.Index-captionBg {
  background-color: rgba(0, 0, 0, 0.55);
}

.Index-captionBg:hover {
  background-color: rgba(0, 0, 0, 0.35);
}

.Index-caption--left {
  right: 10%;
}

.Index-caption--right {
  left: 10%;
}

.Index-title {
  color: #000;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  font-weight: 800;
}

.Index-subtitle,
.Index-date {
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
}

.IndexFollowRace {
  background-color: #aee4b5;
  h2 {
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
  }
  button {
    background: transparent;
    border: 1px solid white;
    color: white;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      color: #aee4b5;
      border: 1px solid white;
      background: white;
    }
  }
}
