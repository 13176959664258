.Turn-bg {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 28rem;
}

.Turn-bg::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    35deg,
    rgba(48, 27, 25, 0.45) 0%,
    rgba(48, 27, 25, 0) 80%,
    rgba(48, 27, 25, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#33301b19', endColorstr='#00301b19',GradientType=1);
  height: 100%;
  width: 100%;
  z-index: 0;
}

.Turn-bg--300 {
  background-image: url("../files/img/turnEpic300.jpg");
}

.Turn-bg--200 {
  background-image: url("../files/img/turnEpic200.jpg");
}

.Turn-bg--100 {
  background-image: url("../files/img/turnEpic100.jpg");
}

.Turn-caption {
  position: absolute;
  bottom: 5%;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.Turn-title {
  color: #f0f1f2;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  font-weight: 800;
  white-space: nowrap;
}
