.Footer {
    min-height: 5rem;
}

.Footer-address {
    border-right: 1px solid #474747;
    color: rgba(145, 145, 145, 1);
    ul {
        li {
            font-size: 1.5rem;
        }
    }
}

.Footer-address-link {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    color: rgba(145, 145, 145, 1);
}

.Footer-address-link:hover {
    color: rgba(145, 145, 145, .5);
    text-decoration: none;
}

.Footer-address span {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    margin-left: .5rem;
}

.Footer-title {
    color: #f0f1f2;
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
}

.Footer-partner {
    filter: brightness(0) invert(1);
    width: 300px;
}

.Footer-org {
    width: 220px;
}

.Footer-shield, .Footer-org {
    filter: grayscale(100%);
}

.Footer-shield:hover, .Footer-org:hover, .Foorter-partner:hover {
    filter: grayscale(0);
}

.Footer-shield {
    height: 70px;
}

.Footer-secondary {
    background-color: #f0f1f2;
}

@media screen and (max-width:576px) {
    .col-shield {
        width: 33.33%;
        margin-top: 1rem;
    }
}