.Rules-card {
    background-color: transparent;
    border: none;
}

.Rules-header {
    background-color: transparent;
}

.Rules-link:hover {
    color: transparent;
    text-decoration: none;
}
.Rules-linkIcon:hover, .Rules-linkTitle:hover {
    color: rgba(68, 68, 68, .75);
    text-decoration: none;
}

.Rules-linkTitle {
    color: rgba(68, 68, 68, 1);
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
}

.Rules-linkIcon {
    color: rgba(174, 228, 181, 1);
    font-size: 1.1rem;
}
