.CarouselIndex-inner {
  height: 25vh;
}

.CarouselIndex-item.active,
.CarouselIndex-item-next,
.CarouselIndex-item-prev {
  display: block;
}

.CarouselIndex-item {
  height: 25vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.CarouselIndex-item1 {
  background-image: url("../files/img/carouselIndex101.jpg");
}

.CarouselIndex-item2 {
  background-image: url("../files/img/carouselIndex102.jpg");
}

.CarouselIndex-item3 {
  background-image: url("../files/img/carouselIndex103.jpg");
}

.CarouselIndex-item4 {
  background-image: url("../files/img/carouselIndex104.jpg");
}

.CarouselIndex-item5 {
  background-image: url("../files/img/carouselIndex105.jpg");
}

.CarouselIndex-control--left,
.CarouselIndex-control--right {
  opacity: 1;
}

.CarouselIndex-control--left {
  justify-content: flex-start;
}

.CarouselIndex-control--right {
  justify-content: flex-end;
}

.CarouselIndex-icon {
  background-color: #aee4b5;
  color: #80a685;
  font-size: 2rem;
  height: 80px;
  padding: 25px 0 0 0;
  position: absolute;
  width: 50px;
}

@media screen and (max-width: 992px) {
  .CarouselIndex .carousel-indicators {
    display: none;
  }
}

@media screen and (min-width: 720px) {
  .CarouselIndex-inner {
    height: 50vh;
  }

  .CarouselIndex-item {
    height: 50vh;
  }
}

@media screen and (min-width: 992px) {
  .CarouselIndex-inner {
    height: 73vh;
  }

  .CarouselIndex-item {
    height: 90vh;
  }
}
