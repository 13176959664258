.Stage-subtitle {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.Stage-data{
    background-color: #fff;
    color: rgba(174, 228, 181, 1);
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    font-size: .95rem;
}

.Stage-data span {
    color: #8e8e8e;
    font-size: 1.3rem;
}

.CarouselStage1-inner {
    height: 50vh;
}

.CarouselStage1-item.active,
.CarouselStage1-item-next,
.CarouselStage1-item-prev {
  display: block;
}

.CarouselStage1-item {
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}

.CarouselStage1-item1 {
    background-image: url("../files/img/carouselStage101.jpg");
}

.CarouselStage1-item2 {
    background-image: url("../files/img/carouselStage102.jpg");
}

.CarouselStage1-item3 {
    background-image: url("../files/img/carouselStage103.jpg");
}

.CarouselStage2-item1 {
    background-image: url("../files/img/carouselStage201.jpg");
}

.CarouselStage2-item2 {
    background-image: url("../files/img/carouselStage202.jpg");
}

.CarouselStage2-item3 {
    background-image: url("../files/img/carouselStage203.jpg");
}

.CarouselStage3-item1 {
    background-image: url("../files/img/carouselStage301.jpg");
}

.CarouselStage3-item2 {
    background-image: url("../files/img/carouselStage302.jpg");
}

.CarouselStage3-item3 {
    background-image: url("../files/img/carouselStage303.jpg");
}

.CarouselStage4-item1 {
    background-image: url("../files/img/carouselStage401.jpg");
}

.CarouselStage4-item2 {
    background-image: url("../files/img/carouselStage402.jpg");
}

.CarouselStage4-item3 {
    background-image: url("../files/img/carouselStage403.jpg");
}

.CarouselStage5300-item1 {
    background-image: url("../files/img/carouselStage530001.jpg");
}

.CarouselStage5300-item2 {
    background-image: url("../files/img/carouselStage530002.jpg");
}

.CarouselStage5300-item3 {
    background-image: url("../files/img/carouselStage530003.jpg");
}

.CarouselStage5200-item1 {
    background-image: url("../files/img/carouselStage520001.jpg");
}

.CarouselStage5200-item2 {
    background-image: url("../files/img/carouselStage520002.jpg");
}

.CarouselStage5200-item3 {
    background-image: url("../files/img/carouselStage520003.jpg");
}

.CarouselStage6300-item1 {
    background-image: url("../files/img/carouselStage630001.jpg");
}

.CarouselStage6300-item2 {
    background-image: url("../files/img/carouselStage630002.jpg");
}

.CarouselStage6300-item3 {
    background-image: url("../files/img/carouselStage630003.jpg");
}

.CarouselStage7300-item1 {
    background-image: url("../files/img/carouselStage730001.jpg");
}

.CarouselStage7300-item2 {
    background-image: url("../files/img/carouselStage730002.jpg");
}

.CarouselStage7300-item3 {
    background-image: url("../files/img/carouselStage730003.jpg");
}

.CarouselStage6200-item1 {
    background-image: url("../files/img/carouselStage620001.jpg");
}

.CarouselStage6200-item2 {
    background-image: url("../files/img/carouselStage620002.jpg");
}

.CarouselStage6200-item3 {
    background-image: url("../files/img/carouselStage620003.jpg");
}

.CarouselStage7200-item1 {
    background-image: url("../files/img/carouselStage720001.jpg");
}

.CarouselStage7200-item2 {
    background-image: url("../files/img/carouselStage720002.jpg");
}

.CarouselStage7200-item3 {
    background-image: url("../files/img/carouselStage720003.jpg");
}

.CarouselStage8-item1 {
    background-image: url("../files/img/carouselStage801.jpg");
}

.CarouselStage8-item2 {
    background-image: url("../files/img/carouselStage802.jpg");
}

.CarouselStage8-item3 {
    background-image: url("../files/img/carouselStage803.jpg");
}